import React from "react";

function ThumbUp() {
  return (
    <svg width={27} height={32} viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.35413 26.3925L7.3526 26.3912C6.82916 25.953 6.46144 25.4926 6.08973 25.0125L6.03727 24.9459L6.03239 24.9397L6.03241 24.9397L6.02502 24.9301C5.30671 24.0027 4.53283 23.0034 2.51873 21.9757L2.51692 21.9748C1.55656 21.4822 1.04126 20.3742 1.42602 19.3734L4.14763 12.2943C4.52997 11.2998 5.6533 10.8218 6.7309 10.9336C8.63603 11.1278 10.4861 10.5756 11.9985 9.80748C13.5138 9.03759 14.6873 8.05652 15.2408 7.40273L15.2416 7.40189C16.0645 6.43203 17.0386 4.75052 17.8955 2.91516L7.35413 26.3925ZM7.35413 26.3925C7.87803 26.8293 8.55873 27.2456 9.55691 27.6294L15.798 30.0288C17.1445 30.5465 18.3048 30.2253 19.062 29.6162C19.4124 29.3401 19.687 28.9796 19.8599 28.568L19.8658 28.554L19.8712 28.5398C19.9471 28.3423 19.9876 28.1503 19.9921 27.9665C19.9944 27.8753 19.9878 27.7853 19.9717 27.6981C19.9644 27.6581 19.9489 27.5832 19.9142 27.4939M7.35413 26.3925L19.9142 27.4939M19.9142 27.4939C20.5844 27.1842 21.181 26.7363 21.6645 26.179L21.6718 26.1705L21.6789 26.1619C22.17 25.5696 22.4104 24.9066 22.166 24.2984C22.4679 24.1514 22.7499 23.8867 22.9984 23.5592L22.9993 23.558C23.2633 23.2089 23.4917 22.7852 23.6646 22.3356C23.8362 21.8892 23.9513 21.4203 23.9887 20.9852C24.0262 20.569 23.9907 20.1849 23.8664 19.8768C24.4298 19.62 24.864 19.2571 25.1571 18.8022C25.456 18.3383 25.5679 17.8416 25.5818 17.3908C25.599 16.8795 25.4896 16.4081 25.3293 16.0238C25.1768 15.6585 24.9445 15.2925 24.6421 15.0449C23.4682 14.0823 21.9006 13.4815 20.631 13.1199L20.6297 13.1195C19.9498 12.9268 19.2593 12.7739 18.5617 12.6618L18.5481 12.6596L18.5345 12.6578L18.4629 12.6483C20.4896 10.1531 21.7521 7.12827 22.0999 3.93197L22.0999 3.93197L22.1004 3.92692C22.1654 3.30038 21.854 2.71648 21.3395 2.35633C21.3392 2.35612 21.3389 2.35592 21.3386 2.35572L20.9178 2.06038L20.9178 2.06037L20.9144 2.05805C19.8951 1.34891 18.4413 1.74558 17.8956 2.91493L19.9142 27.4939Z"
        fill="#4DA660"
        stroke="white"
        strokeWidth={2}
      />
    </svg>
  );
}

export default ThumbUp;
